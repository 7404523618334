function openMobileMenu () {
	var mobileMenu = document.getElementById("mobile-menu");
	mobileMenu.classList.toggle("display-block");
}

document.addEventListener("DOMContentLoaded", async function(){

	let platformDataUrl = "https://api.planet.finance/v1/markets/getallplatformdata";
	let allPoolsInfoUrl = "https://api.planet.finance/v1/markets/getallpoolinfo"

	async function getText(url) {
			let x = await fetch(url);
			let y = await x.json();
			return y;
	}

	function commafy( num , decimal = 2, isPercent = false, isApy = true) {
			
			if(isPercent && num > 10000) {
			return "> 10,000";
			} 
			var str = num.toString().split('.');
			if (str[0].length >= 5) {
					str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
			}
			if(innerWidth < 768 && isApy && !isPercent) {
			decimal = 0
			//return str[0];
			}
			if (str[1] && str[1].length >= decimal) {
					str[1] = str[1].substring(0, decimal);
			}

			if(decimal == 0) {
			return str[0];
			}
			return str.join('.');
	}

	let plateformData = await getText(platformDataUrl);
	let poolInfoData = await getText(allPoolsInfoUrl);
	let vaults = poolInfoData.newVaults.active;
	let pools = poolInfoData.newPools.active;
	function compare( a, b ) {
		if ( a.totalApy > b.totalApy ){
		return -1;
		}
		if ( a.totalApy < b.totalApy ){
		return 1;
		}
		return 0;
	}

	vaults.sort( compare );
	pools.sort( compare );
	document.getElementById("tvl").textContent= '$'+commafy(plateformData.tvl, 2, false, true);
	document.getElementById("treasury").textContent= '$'+commafy(plateformData.treasury, 2, false, true);
	document.getElementById("reserves").textContent= '$'+commafy(plateformData.reserve, 2, false, true);
	document.getElementById("aquaburned").textContent= '$'+commafy(plateformData.aqua_burned_usd, 2, false, true);
	document.getElementById("aquaPrice").textContent= '$'+commafy(plateformData.aqua_price, 3, false, false);
	document.getElementById("gammaPrice").textContent= '$'+commafy(plateformData.gamma_price, 3, false, false);

	document.getElementById("firstPool").getElementsByClassName("pool-name")[0].textContent= pools[0].name;
	document.getElementById("firstPool").getElementsByClassName("apy-percent")[0].textContent= commafy(pools[0].totalApy, 2, true)+"%";
	let poolName = pools[0].name.split('-');
	document.getElementById("firstPool").getElementsByTagName("img")[0].src= getTokenIcon(poolName[0]);
	document.getElementById("firstPool").getElementsByTagName("img")[1].src= getTokenIcon(poolName[1].split(' ')[0]);

	document.getElementById("secondPool").getElementsByClassName("pool-name")[0].textContent= pools[1].name;
	document.getElementById("secondPool").getElementsByClassName("apy-percent")[0].textContent= commafy(pools[1].totalApy, 2, true)+"%";
	poolName = pools[1].name.split('-');
	document.getElementById("secondPool").getElementsByTagName("img")[0].src= getTokenIcon(poolName[0]);
	document.getElementById("secondPool").getElementsByTagName("img")[1].src= getTokenIcon(poolName[1].split(' ')[0]);

	document.getElementById("thirdPool").getElementsByClassName("pool-name")[0].textContent= pools[2].name;
	document.getElementById("thirdPool").getElementsByClassName("apy-percent")[0].textContent= commafy(pools[2].totalApy, 2, true)+"%";
	poolName = pools[2].name.split('-');
	document.getElementById("thirdPool").getElementsByTagName("img")[0].src= getTokenIcon(poolName[0]);
	document.getElementById("thirdPool").getElementsByTagName("img")[1].src= getTokenIcon(poolName[1].split(' ')[0]);


	document.getElementById("vaultFirst").getElementsByClassName("pool-name")[0].textContent= vaults[0].name;
	document.getElementById("vaultFirst").getElementsByClassName("apy-percent")[0].textContent= commafy(vaults[0].totalApy, 2, true)+"%";
	document.getElementById("vaultFirst").getElementsByTagName("img")[0].src= getTokenIcon(vaults[0].name);

	document.getElementById("vaultSecond").getElementsByClassName("pool-name")[0].textContent= vaults[1].name;
	document.getElementById("vaultSecond").getElementsByClassName("apy-percent")[0].textContent= commafy(vaults[1].totalApy, 2, true)+"%";
	document.getElementById("vaultSecond").getElementsByTagName("img")[0].src= getTokenIcon(vaults[1].name);

	document.getElementById("vaultThird").getElementsByClassName("pool-name")[0].textContent= vaults[2].name;
	document.getElementById("vaultThird").getElementsByClassName("apy-percent")[0].textContent= commafy(vaults[2].totalApy, 2, true)+"%";
	document.getElementById("vaultThird").getElementsByTagName("img")[0].src= getTokenIcon(vaults[2].name);
});